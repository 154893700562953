// new typography. Cleanup old up above

@mixin headline1 {
  font-family: var(--font-primary);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
}

%headline1 {
  @include headline1;
}

@mixin headline2 {
  font-family: var(--font-primary);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}

%headline2 {
  @include headline2;
}

@mixin headline3 {
  font-family: var(--font-primary);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

%headline3 {
  @include headline3;
}

@mixin title1 {
  font-family: var(--font-primary);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

%title1 {
  @include title1;
}

@mixin title2 {
  font-family: var(--font-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

%title2 {
  @include title2;
}

@mixin tagline {
  font-family: var(--font-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-transform: uppercase;
}

%tagline {
  @include tagline;
}

@mixin button {
  font-family: var(--font-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

%button {
  @include button;
}

@mixin body1 {
  font-family: var(--font-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

%body1 {
  @include body1;
}

@mixin body2 {
  font-family: var(--font-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

%body2 {
  @include body2;
}

@mixin body3 {
  font-family: var(--font-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

%body3 {
  @include body3;
}

@mixin link {
  font-family: var(--font-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-decoration-line: underline;
}

%link {
  @include link;
}

@mixin label {
  font-family: var(--font-primary);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

%label {
  @include label;
}

@mixin caption1 {
  font-family: var(--font-primary);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

%caption1 {
  @include caption1;
}

@mixin caption2 {
  font-family: var(--font-primary);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

%caption2 {
  @include caption2;
}

@mixin caption3 {
  font-family: var(--font-primary);
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

%caption3 {
  @include caption3;
}

@mixin display2 {
  font-family: var(--font-primary);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 74px;
  text-transform: uppercase;
}

%display2 {
  @include display2;
}

