// >= tablet-portrait <
$breakpoint-sm: 744;

// >= tablet-landscape <
$breakpoint-md: 960;

// >= desktop
$breakpoint-lg: 1200;

// breakpoints cascading
@mixin tablet-portrait {
  @media (min-width: #{$breakpoint-sm}px) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: #{$breakpoint-md}px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-lg}px) {
    @content;
  }
}
