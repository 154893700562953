@import '@styles/media';
@import '@styles/typography';

.root {
  height: 100vh;

  display: grid;
  grid-template-columns: 1fr;

  @include tablet-landscape {
    grid-template-columns: 1fr 1fr;
  }
}

.img {
  display: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &:last-of-type {
    @include tablet-landscape {
      display: block;
    }
  }
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 50px 8px 35px;
}

.loginSection {
  width: 100%;
  max-width: 482px;
}

.header {
  @include headline3;

  margin-bottom: 24px;
  text-align: center;

  @include tablet-portrait {
    @include headline2;
  }

  @include desktop {
    @include headline1;
  }

  .escher {
    color: var(--green1);
  }
}

.googleBtn {
  width: 100%;

  .icon {
    width: 18px;
    height: 18px;
  }
}

.delimiter {
  margin: 32px 0;
}

.footer {
  gap: 12px;
  display: flex;
}

// maintenance
.loginSectionMaintenance {
  width: 100%;
  max-width: 682px;
}

.maintenance {
  @include headline2;
  text-align: center;
  color: #ccc;

  .escher {
    color: var(--green1);
  }
}
